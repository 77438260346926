import auth from '../modules/auth/routes';
import orders from '../modules/orders/routes';
import users from '../modules/users/routes';
import reports from '../modules/reports/routes';
import tools from '../modules/tools/routes';
import bagging from '../modules/bagging/routes';
import configuration from '../modules/configuration/routes';
import barangayCluster from '../modules/barangay-cluster/routes';

export default [
  ...auth,
  ...orders,
  ...users,
  ...reports,
  ...tools,
  ...bagging,
  ...configuration,
  ...barangayCluster,
];
